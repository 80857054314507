import { useQuery } from "@tanstack/vue-query";

export const useAppStore = defineStore('app', () => {
  const user = useUser();
  const page = ref(1);
  const organisation = ref<App.Data.OrganisationData>();

  const api = useApi();
  async function init() {
    const response = await api.v1.config.defaults();
    organisation.value = response.defaultOrganisation;
  }

  const organisationListUserQuery = useQuery({
    queryKey: ['organisationListUser', page],
    queryFn: () => api.v1.organisation.listUser({ userId: user.value.id }),
    enabled: !!user.value.id // Ensure the query runs only if userId is available
  })

  const organisationListUser = computed(() => {
    return organisationListUserQuery?.data?.value?.data?.map((org: App.Data.OrganisationData) => {
      return {
        id: org.id,
        slot: 'organisation',
        label: org.name,
        type: org.type,
        click: () => {
          console.log('clicked');
        },
      };
    }) || [];
  });




  return {
    organisation,
    init,
    organisationListUserQuery,
    organisationListUser,
  }
});